html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: #ffffff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer p {
  margin: 10px 0 0;
  font-size: 14px;
}