/* Card Styling */
.skills-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Skills Grid */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 20px;
  justify-content: center;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .skills-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 480px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
