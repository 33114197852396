/* Blog Posts Grid */
.blog-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Six columns */
  gap: 24px;
  margin: 0 auto;
  padding: 20px;
  max-width: 100%; /* Adjust as needed */
}

/* Blog Card Styling */
.blog-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff; /* Clean white background */
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

/* Image Styling */
.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #4e54c8;
  transition: opacity 0.3s;
}

.blog-card:hover .blog-image {
  opacity: 0.9;
}

/* Content Styling */
.blog-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.blog-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #4e54c8; /* Modern blue accent color */
  margin-bottom: 10px;
  line-height: 1.3;
}

.blog-author {
  font-size: 0.875rem;
  color: #777;
  margin-bottom: 8px;
  font-style: italic;
}

.blog-excerpt {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
  flex-grow: 1;
}

/* Button Styling */
.read-more-link {
  margin-top: 20px;
  align-self: flex-start;
  color: #ffffff;
  background-color: #4e54c8; /* Consistent accent color */
  text-decoration: none;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  display: inline-block;
}

.read-more-link:hover {
  background-color: #4e54c8;
  transform: translateY(-3px);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .blog-posts {
    grid-template-columns: repeat(3, 1fr); /* Four columns */
  }
}

@media (max-width: 992px) {
  .blog-posts {
    grid-template-columns: repeat(3, 1fr); /* Three columns */
  }
}

@media (max-width: 768px) {
  .blog-posts {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }
  .blog-image {
    height: 180px;
  }
  .blog-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 576px) {
  .blog-posts {
    grid-template-columns: 1fr; /* Single column */
  }
  .blog-image {
    height: 150px;
  }
  .blog-title {
    font-size: 1.2rem;
  }
  .blog-excerpt {
    font-size: 0.9rem;
  }
}
