/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

/* Body Styling */
body {
  background: #f3f4f6;
  color: #333;
  overflow-x: hidden;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo h1 {
  font-size: 1.8em;
  color: #4e54c8;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.logo h1:hover {
  color: #63b3ed;
}

/* Navigation */
.nav {
  display: flex;
  gap: 30px;
}

.nav-link {
  color: #555;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #4e54c8;
}

/* Mobile Menu Button */
.mobile-menu {
  display: none;
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
}

/* Call-to-Action Button */
.cta .download-btn {
  padding: 10px 20px;
  background-color: #4e54c8;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta .download-btn:hover {
  background-color: #63b3ed;
  transform: translateY(-3px);
}

/* Hero Section */
.hero {
  position: relative;
  display: flex;
  padding: 35px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  text-align: center;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.hero > * {
  display: block;
}

.hero-title {
  font-size: 2.5em;
  color: #fff;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.2em;
  color: #d1d5db;
  font-weight: 300;
}

.hero-summary {
  font-size: 0.95em;
  color: #e0e7ff;
  line-height: 1.5;
  margin: 15px auto;
  max-width: 600px;
}

.profile-pic {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-bottom: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

.social-links {
  display: flex;
  gap: 30px;
  justify-content: center;
}

.social-icon {
  font-size: 1.8em;
  color: #ffffff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #63b3ed;
  transform: translateY(-5px);
}

/* Sections */
.section {
  flex: 1;
  /* border: 1px solid #ccc; */
  background-color: #f8f9fa;
  border-radius: 15px;
  margin: 10px;
  padding: 2rem 1rem;
  background-color: #f7f8fa;
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: justify;
}

.section h2::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: #4e54c8;
  margin-top: 10px;
  border-radius: 3px;
}

/* Card Styling */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 12px;
  padding: 1.5rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h3 {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #555;
}

.card svg {
  margin-bottom: 10px;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .nav {
    flex-direction: column;
    display: none;
    gap: 20px;
  }

  .nav.open {
    display: flex;
    align-items: center;
  }

  .hero-title {
    font-size: 2.2em;
  }

  .hero-summary {
    font-size: 0.9em;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 30px 15px;
  }

  .hero-title {
    font-size: 1.8em;
  }

  .hero-subtitle {
    font-size: 0.95em;
  }

  .hero-summary {
    font-size: 0.85em;
    max-width: 400px;
  }

  .nav-link {
    font-size: 0.95em;
  }

  .social-icon {
    font-size: 1.5em;
  }

  .cta .download-btn {
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .hero-title {
    font-size: 1.5em;
  }

  .hero-subtitle {
    font-size: 0.85em;
  }

  .hero-summary {
    font-size: 0.75em;
    max-width: 300px;
  }

  .nav-link {
    font-size: 0.85em;
  }

  .cta .download-btn {
    padding: 8px 15px;
    font-size: 0.85em;
  }

  .social-links {
    gap: 10px;
  }

  .social-icon {
    font-size: 1.3em;
  }
}
