.eca-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between; /* Ensures spacing between items */
}

.eca-card {
  flex: 1 1 300px; /* Adjusts to at least 300px, scales as needed */
  margin: 10px;
  box-sizing: border-box;
}

.eca-card-title {
  font-size: 1.6rem;
  color: #2c3e50;
  margin-bottom: 5px;
}

.eca-card-subtitle,
.eca-card-duration {
  font-size: 1rem;
  color: #7f8c8d;
  margin: 5px 0;
}

.eca-card-description {
  font-size: 1rem;
  color: #34495e;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .eca-card {
    flex: 1 1 45%; /* Adjusts to two cards per row on medium-large screens */
  }
  .eca-card-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .eca-card {
    flex: 1 1 48%; /* Adjusts to two cards per row on medium screens */
  }
  .eca-card-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .eca-card {
    flex: 1 1 100%; /* Full width on smaller screens */
  }
  .eca-card-title {
    font-size: 1.2rem;
  }
  .eca-card-description {
    font-size: 0.9rem;
  }
  .eca-section {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .eca-card-title {
    font-size: 1.1rem;
  }
  .eca-card-subtitle,
  .eca-card-duration {
    font-size: 0.9rem;
  }
  .eca-card-description {
    font-size: 0.8rem;
  }
  .eca-section {
    padding: 10px;
  }
}
