.contact-section {
  padding: 50px 20px;
  background-color: #f4f7fa;
  display: flex;
  justify-content: center;
}

.contact-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: start;
  flex-wrap: wrap;
}

.contact-info,
.contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
}

.contact-info {
  flex: 1;
  padding: 20px;
  border-radius: 12px;
  transition: transform 0.3s;
  background-color: #4e54c8;
  color: #fff;
  border-radius: 16px 0 0 16px;
  display: flex;
  flex-direction: column;
}

.contact-info h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.contact-info p {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.6;
  display: flex;
  align-items: center;
}

.contact-info span {
  font-weight: 600;
  margin-left: 5px;
}

.contact-info a {
  color: #fff;
  text-decoration: none;
  margin-left: 8px;
  transition: color 0.3s;
}

.contact-info a:hover {
  color: #c0c9f7;
}

.contact-info svg {
  font-size: 1.5rem;
}

.social-handles {
  margin-top: 20px;
}

.social-handles h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.social-handles a {
  color: #fff;
  font-size: 1.5rem;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-handles a:hover {
  color: #c0c9f7;
}

.contact-form {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 0 16px 16px 0;
}

.contact-form h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.contact-form textarea {
  resize: none;
  min-height: 100px;
}

.contact-form button {
  background-color: #4e54c8;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #4e54c8;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-info h3 {
    font-size: 1.5rem;
  }

  .contact-form h3 {
    font-size: 1.3rem;
  }

  .contact-info p,
  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-info svg {
    font-size: 1.3rem;
  }

  .social-handles a {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 15px;
    gap: 20px;
  }

  .contact-info {
    border-radius: 16px 16px 0 0;
  }

  .contact-form {
    border-radius: 0 0 16px 16px;
  }

  .contact-info p {
    flex-direction: column;
    align-items: flex-start;
  }


}

@media (max-width: 480px) {
  .contact-section {
    padding: 30px 10px;
  }

  .contact-info h3,
  .contact-form h3 {
    font-size: 1.2rem;
  }

  .contact-info p {
    font-size: 0.8rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.8rem;
    padding: 8px;
  }

  .contact-form button {
    font-size: 0.8rem;
    padding: 10px 16px;
  }

  .contact-info svg {
    font-size: 1rem;
  }

  .social-handles a {
    font-size: 1rem;
    margin-right: 10px;
  }
}
