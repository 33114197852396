.landing-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .overview, .benefits {
    margin-bottom: 30px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .footer {
    text-align: center;
  }
  

  h3 {
    margin-top: 30px;
  }