/* General Header Styling */
.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

/* Logo Styling */
/* .logo {
  flex: 1;
} */

/* Navigation Menu */
.nav {
  display: flex;
  gap: 20px;
  flex: 2;
  justify-content: center;
  align-items: center;
}

/* Navigation Links Styling */
.nav .nav-link {
  font-size: 18px;
  margin: 0 15px;
  color: #333;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.nav .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #4e54c8;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav .nav-link:hover::after {
  width: 100%;
  left: 0;
}

/* Hover color change (optional) */
.nav .nav-link:hover {
  color: #4e54c8;
}


/* CTA Button */
/* .cta {
  flex: 1;
  display: flex;
  justify-content: flex-end;
} */

/* Hamburger Menu Styling */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1100;
}

.menu-icon {
  width: 30px;
  height: 3px;
  background-color: #333;
  display: block;
  position: relative;
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: #333;
  display: block;
  position: absolute;
  left: 0;
  transition: transform 0.3s;
}

.menu-icon::before {
  top: -8px;
}

.menu-icon::after {
  top: 8px;
}

.menu-open .menu-icon {
  background-color: transparent;
}

.menu-open .menu-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-open .menu-icon::after {
  transform: rotate(-45deg);
  top: 0;
}

/* Full-Screen Overlay Navigation for Mobile and Tablet */
@media (max-width: 1024px) {
  .menu-toggle {
    display: block;
  }

  /* .logo {
    flex: 1;
  } */

  .nav {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 100%; /* Hidden off-screen by default */
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    justify-content: center;
    align-items: center;
    padding: 20px;
    transition: left 0.5s ease;
    z-index: 1000;
  }

  .nav.open {
    left: 0; /* Slide in to full screen */
  }

  .center-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* .logo {
    display: block;
  } */
  /* 
  .cta {
    display: block;
    margin-top: 10px;
    text-align: center;
  } */

  .download-btn {
    font-size: 14px;
    padding: 8px 16px;
    width: 100%; /* Ensures button stretches for mobile */
  }
}

/* Tablet-Specific Styling */
@media (min-width: 768px) and (max-width: 1024px) {
  .nav {
    display: none; /* Use hamburger menu */
  }

  .menu-toggle {
    display: block;
  }

  .center-container {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-btn {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* Responsive Styling */
@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .download-btn {
    text-align: center;
  }
}
