/* Experience Timeline */
.experience-timeline {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.timeline-item {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.timeline-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.15);
}

.timeline-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.company-logo {
  width: 45px;
  height: 45px;
  border-radius: 8px;
  margin-right: 12px;
}

.timeline-role {
  font-size: 1rem;
  color: #222;
  margin: 0;
}

.timeline-company {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

.timeline-duration {
  display: block;
  font-size: 0.75rem;
  color: #888;
}

.timeline-description {
  font-size: 0.85rem;
  color: #444;
  line-height: 1.4;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .company-logo {
    width: 40px;
    height: 40px;
  }

  .timeline-role {
    font-size: 0.9rem;
  }

  .timeline-description {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .timeline-role {
    font-size: 0.8rem;
  }

  .timeline-company,
  .timeline-duration {
    font-size: 0.7rem;
  }

  .timeline-description {
    font-size: 0.75rem;
  }
}
