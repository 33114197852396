.category-section {
  margin-bottom: 40px;
}

.category-title {
  font-size: 1.5em;
  color: #4e54c8;
  margin-bottom: 20px;
  border-bottom: 2px solid #4e54c8;
  padding-bottom: 5px;
}

/* Category Filter Styling */
.category-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-filter label {
  font-size: 1em;
  color: #333;
}

.category-filter select {
  padding: 5px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ddd;
}


/* Projects Grid Styling */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.project-card {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensures the card takes up full available space */
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-bottom: 2px solid #4e54c8;
}

.project-content {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the link stays at the bottom */
}

.project-card h3 {
  color: #4e54c8;
  margin-bottom: 8px;
  font-size: 1.2em;
}

.project-card p {
  margin: 5px 0;
  color: #333;
  font-size: 0.9em;
}

.project-description {
  margin: 10px 0;
  line-height: 1.4;
  font-size: 0.85em;
}

.project-links {
  padding: 10px 15px;
  background: #f8f9fa;
  border-top: 1px solid #ddd;
  margin-top: auto; /* Pushes the link section to the bottom */
}

.project-link {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: #4e54c8;
  font-size: 0.85em;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-link:hover {
  color: #63b3ed;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }

  .project-card h3 {
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .project-card p {
    font-size: 0.85em;
  }

  .category-title {
    font-size: 1.2em;
  }
}


